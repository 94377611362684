import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  padding: 24px;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  @media (max-width: 767px) {
    padding: 15px;
  }
`;

const Name = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  & h1 {
    cursor: pointer;
    z-index: 100;

    @media (max-width: 767px) {
      width: 100%;
      text-align: center;
      cursor: auto;
    }
  }

  @media (max-width: 767px) {
    height: calc(100% - 40px - 18px);
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;

  width: 100%;

  & .details-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-self: flex-end;

    width: 100%;
    margin: 0 0 0 auto;

    & .mobile-wrapper {
      margin: 0;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-self: flex-end;

      @media (max-width: 767px) {
        display: block;
      }
    }

    @media (max-width: 767px) {
      justify-content: space-between;

      & > div:first-of-type {
        width: 120px;
      }
    }
  }

  & div {
    margin: 0 0 0 45px;

    & p {
      margin: 0;
    }

    @media (max-width: 767px) {
      margin: 0;
    }
  }
`;

const LeftImages = styled.div`
  position: relative;

  width: auto;
  max-width: 33.333%;
  height: 100%;

  opacity: ${props => props.opacity};

  & img {
    height: 100%;
    max-height: calc(100% - 30px);
    object-fit: contain;
    object-position: top left;
  }
`;

const MiddleImages = styled.div`
  position: relative;
  display: flex;

  width: auto;
  max-width: 33.333%;
  height: 100%;

  opacity: ${props => props.opacity};

  & img {
    height: 100%;
    max-height: calc(100% - 30px);
    object-fit: contain;
    object-position: bottom center;
  }
`;

const RightImages = styled.div`
  position: relative;

  width: auto;
  max-width: 33.333%;
  height: 100%;

  opacity: ${props => props.opacity};

  & img {
    height: 100%;
    max-height: calc(100% - 30px);
    object-fit: contain;
    object-position: top right;
  }
`;

const TopRowImages = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  height: calc(50% - 15px);

  @media (max-width: 767px) {
    display: none;
  }
`;

const BottomRowImages = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  height: calc(50% - 15px);

  @media (max-width: 767px) {
    display: none;
  }
`;

const Index = ({ data }) => {
  const [activeImages, setActiveImages] = useState(null);

  const [activeJamesSlideNumber, setActiveJamesSlideNumber] = useState(0);
  const numberOfJamesSlides = data.prismicHomepage.data.body.length;

  const [activeHaySlideNumber, setActiveHaySlideNumber] = useState(0);
  const numberOfHaySlides = data.prismicHomepage.data.body1.length;

  const [activeBrownSlideNumber, setActiveBrownSlideNumber] = useState(0);
  const numberOfBrownSlides = data.prismicHomepage.data.body2.length;

  const jamesHoverImages = data.prismicHomepage.data.body.map(
    (content, index) => {
      if (content.slice_type === "image") {
        return (
          <img
            srcSet={content.primary.image.fluid.srcSetWebp}
            src={content.primary.image.fluid.srcWebp}
            alt={content.primary.image.alt}
          />
        );
      }

      // if (content.slice_type === "video") {
      //   return (
      //     <video
      //       key={`single_video_james_${content.id}_${index}`}
      //       src={content.primary.video.url}
      //       muted
      //       loop
      //       autoplay
      //     />
      //   );
      // }
    }
  );

  const hayHoverImages = data.prismicHomepage.data.body1.map(
    (content, index) => {
      if (content.slice_type === "image") {
        return (
          <img
            srcSet={content.primary.image.fluid.srcSetWebp}
            src={content.primary.image.fluid.srcWebp}
            alt={content.primary.image.alt}
            key={`single_image_hay_${content.id}_${index}`}
          />
        );
      }

      // if (content.slice_type === "video") {
      //   return (
      //     <video
      //       key={`single_video_hay_${content.id}_${index}`}
      //       src={content.primary.video.url}
      //       muted
      //       loop
      //       autoplay
      //     />
      //   );
      // }
    }
  );

  const brownHoverImages = data.prismicHomepage.data.body2.map(
    (content, index) => {
      if (content.slice_type === "image") {
        return (
          <img
            srcSet={content.primary.image.fluid.srcSetWebp}
            src={content.primary.image.fluid.srcWebp}
            alt={content.primary.image.alt}
            key={`single_image_brown_${content.id}_${index}`}
          />
        );
      }

      // if (content.slice_type === "video") {
      //   return (
      //     <video
      //       key={`single_video_brown_${content.id}_${index}`}
      //       src={content.primary.video.url}
      //       muted
      //       loop
      //       autoplay
      //     />
      //   );
      // }
    }
  );

  useEffect(() => {
    if (activeImages === `james`) {
      const intervalJames = setInterval(() => {
        if (activeJamesSlideNumber < numberOfJamesSlides - 1) {
          setActiveJamesSlideNumber(activeJamesSlideNumber + 1);
        } else {
          setActiveJamesSlideNumber(0);
        }
      }, 1000);
      return () => clearInterval(intervalJames);
    }

    if (activeImages === `hay`) {
      const intervalHay = setInterval(() => {
        if (activeHaySlideNumber < numberOfHaySlides - 1) {
          setActiveHaySlideNumber(activeHaySlideNumber + 1);
        } else {
          setActiveHaySlideNumber(0);
        }
      }, 1000);
      return () => clearInterval(intervalHay);
    }

    if (activeImages === `brown`) {
      const intervalBrown = setInterval(() => {
        if (activeBrownSlideNumber < numberOfBrownSlides - 1) {
          setActiveBrownSlideNumber(activeBrownSlideNumber + 1);
        } else {
          setActiveBrownSlideNumber(0);
        }
      }, 1000);
      return () => clearInterval(intervalBrown);
    }
  }, [
    activeImages,
    activeJamesSlideNumber,
    activeHaySlideNumber,
    activeBrownSlideNumber,
  ]);

  return (
    <Page>
      <TopRowImages>
        <LeftImages opacity={activeImages === `james` ? 1 : 0}>
          {/* {data.prismicHomepage.data.james_randomise_order === true
          ? jamesHoverImages.sort(() => Math.random() - 0.5)
          : jamesHoverImages[activeJamesSlideNumber]} */}
          {jamesHoverImages[activeJamesSlideNumber]}
        </LeftImages>

        <RightImages opacity={activeImages === `brown` ? 1 : 0}>
          {/* {data.prismicHomepage.data.brown_randomise_order === true
          ? brownHoverImages.sort(() => Math.random() - 0.5)
          : brownHoverImages[activeBrownSlideNumber]} */}
          {brownHoverImages[activeBrownSlideNumber]}
        </RightImages>
      </TopRowImages>

      <Name>
        <h1
          onMouseOver={() => setActiveImages(`james`)}
          onMouseLeave={() => setActiveImages(null)}
        >
          James
        </h1>
        <h1
          onMouseOver={() => setActiveImages(`hay`)}
          onMouseLeave={() => setActiveImages(null)}
        >
          Hay
        </h1>
        <h1
          onMouseOver={() => setActiveImages(`brown`)}
          onMouseLeave={() => setActiveImages(null)}
        >
          Brown
        </h1>
      </Name>

      <BottomRowImages>
        <MiddleImages opacity={activeImages === `hay` ? 1 : 0}>
          {/* {data.prismicHomepage.data.hay_randomise_order === true
          ? hayHoverImages.sort(() => Math.random() - 0.5)
          :  */}
          {hayHoverImages[activeHaySlideNumber]}
        </MiddleImages>
      </BottomRowImages>

      <Details>
        <div className="details-container">
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicHomepage.data.text_1.html,
            }}
          />
          <div className="mobile-wrapper">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicHomepage.data.text_2.html,
              }}
            />
            {/* <div
              dangerouslySetInnerHTML={{
                __html: data.prismicHomepage.data.text_3.html,
              }}
            /> */}
          </div>
        </div>
      </Details>
    </Page>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    prismicHomepage {
      data {
        text_1 {
          html
        }
        text_2 {
          html
        }
        text_3 {
          html
        }
        james_randomise_order
        body {
          ... on PrismicHomepageBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
          ... on PrismicHomepageBodyVideo {
            id
            slice_type
            primary {
              video {
                url
              }
            }
          }
        }
        hay_randomise_order
        body1 {
          ... on PrismicHomepageBody1Image {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
          ... on PrismicHomepageBody1Video {
            id
            slice_type
            primary {
              video {
                url
              }
            }
          }
        }
        brown_randomise_order
        body2 {
          ... on PrismicHomepageBody2Image {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
          ... on PrismicHomepageBody2Video {
            id
            slice_type
            primary {
              video {
                url
              }
            }
          }
        }
      }
    }
  }
`;
